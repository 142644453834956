import "./App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import StoryInput from "./components/StoryInput";
import { Typography, Button, Input, Row, Col, Spin } from "antd";
import { Dna } from "react-loader-spinner";
import { Alert, Space } from "antd";
import Form from "react-bootstrap/Form";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { SafetyOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "./api/axios";
import SelectTopic from "./components/SelectTopic";
import MCQ from "./components/Mcq";
import ScrollButton from "./components/ScrollButton";
import { Navigation } from "./components/Navigation";
import Sidebar from "./components/Sidebar";
import LoadingBar from "react-top-loading-bar";
import { GlobalContext } from "./context/GlobalContext";
import { useContext } from "react";
const { Title } = Typography;
const HEADING = {
  topic: "Topic",
  story: "Clinical vignette/scenario",
  variables: "List of variables in selected theme",
  questionCategories: "Question Categories",
  question: "Questions",
  Categories: "Question Categories",
  storyCategories: "Question Topic",
  Correct: "Correct Options",
  Correct1: "Correct Options",
  options: "Other Options",
  optionSection: "Distractor Options",
  previewButton: "Generate Items",
  generateButton: "Download TeX File",
  addQuestionCategory: "Add Question Category",
  addQuestion: "Add Question",
};

const NavigateWrapper = () => {
  const navigate = useNavigate();

  return <App navigate={navigate} />;
};

function App(props) {
  const navigate = useNavigate();
  const [story, setStory] = useState([""]);
  const [fields, setFields] = useState([]);
  const [storyInputRef, setStoryInputRef] = useState([]);
  const [topic, setTopic] = useState("");
  const [topicData, setTopicData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [variables, setVariables] = useState([]);
  const [variableAlert, setVariableAlert] = useState("");
  const [noOfMcqs, setNoOfMcqs] = useState(0);
  const [mcqItems, setMcqItems] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [possibleCombinations, setPossibleCombinations] = useState(0);
  const [codes, setCodes] = useState([]);
  const [showTopic, setShowTopic] = useState(false);
  const [casesList, setCasesList] = useState([]);
  const [showMcqLoader, setShowMcqLoader] = useState(false);
  const [splitStory, setSplitStory] = useState(true);
  const [variableCount, setVariableCount] = useState();
  const [possibleCombinationsData, setPossibleCombinationsData] = useState([]);
  const [progress, setProgress] = useState(30);
  const [showPage, setShowPage] = useState(false);
  const [themeCategories, setThemeCategories] = useState([])
  const { state, dispatch } = useContext(GlobalContext);
  useEffect(() => {
    onStoryInputSubmit();
    axios
      .get("fetch_topic/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        const Data = res?.data?.topics?.map((item) => ({
          id: item.id,
          label: item.name,
          value: item.name,
        }));
        setTopicData(Data);
        setShowTopic(true);
        setVariableCount(res?.data?.variables_count);
      })
      .catch((err) => {
        setShowTopic(true);
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }, []);
  useEffect(() => {
    onStoryInputSubmit();
    axios
      .get("fetch_categories/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        const Data = res?.data?.map((item) => ({
          label: item.name,
          selected: false,
        }));
        setCategoryData(Data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }, []);
  useEffect(() => {
    // Check if the data is ordered by code
    if (variables.length) {
      console.log("VARIABLES IN USEEFFECT----", variables);
      const isOrderedByCode = variables.every((variable, index, array) => {
        if (index === array.length - 1) {
          return true; // Last element, no need to compare
        }
        return variable.code <= array[index + 1].code;
      });

      if (isOrderedByCode) {
        // If not ordered by code, reorder the data
        const reorderedVariables = [...variables].sort(
          (a, b) => a.code - b.code
        );
        setVariables(reorderedVariables);
      }
    }
  }, []); //variables removed from dependency array
  useEffect(() => {
    axios
      .get("fetch_variable_by_topic/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.variables) {
          console.log("RESPONSE.DATA-----", res.data);
          setVariables(res.data.variables);

          console.log("VARIABLES----", variables);
          setCodes(res.data.variables.map((elem) => elem.code));
        } else {
          console.log("HELLOOO------");
        }
        // setVariables(res?.data?.variables);
        // console.log('VARIABLES----',variables)
        // setCodes(res?.data?.variables?.map((elem) => elem.code));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }, [topic]);
  function resetStates() {
    setStory([""]);
    setMcqItems([]);
    setPossibleCombinations(0);
    setNoOfMcqs(0);
  }
  useEffect(() => {
    resetStates();
  }, [topic]);
  useEffect(() => {
    setMcqItems([]);
    setNoOfMcqs(0);
    if (story[0]?.length === 0 || story[0] === "") {
      setPossibleCombinations(0);
    }
  }, [story]);
  function getMoreStories() {
    let categories = [];
    for (let i = 0; i < categoryData.length; i++) {
      if (categoryData[i]?.selected === true) {
        categories.push(categoryData[i]?.label);
      }
    }
    setShowMcqLoader(true);
    axios
      .post(
        "get_stories/",
        {
          stories: story,
          topic,
          categories,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        let minStoryTotal = Number.MAX_SAFE_INTEGER;
        for (const data of res?.data?.data) {
          if (data.story_total < minStoryTotal) {
            minStoryTotal = data.story_total;
          }
        }
        let storiesArr = [];
        for (let i = 0; i < Object.values(res?.data?.data)?.length; i++) {
          storiesArr.push(Object.values(res?.data?.data)[i]?.story);
        }
        if (storiesArr?.length) {
          setStory(storiesArr);
          setPossibleCombinations(minStoryTotal);
        } else {
          alert(
            "Sorry! we can not fit your story to any case in your selected theme"
          );
        }
        setPossibleCombinationsData(res?.data?.data);
        setShowMcqLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setShowMcqLoader(false);
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }
  function findMinMinValue(data) {
    let minValue = Infinity;
    data.forEach((obj) => {
      if (obj.min !== 0 && obj.min < minValue) {
        minValue = obj.min;
      }
    });

    return minValue;
  }
  function getComibinations() {
    let categories = [];
    for (let i = 0; i < categoryData.length; i++) {
      if (categoryData[i]?.selected === true) {
        categories.push(categoryData[i]?.label);
      }
    }
    axios
      .post(
        "get_possible_mcqs_total/",
        {
          categories,
          stories: possibleCombinationsData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        const minValue = findMinMinValue(res?.data?.result);
        setPossibleCombinations(minValue);
      })
      .catch((err) => {
        console.log(err, "err");
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }
  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  function generateMcqs() {
    let categories = [];
    for (let i = 0; i < categoryData.length; i++) {
      if (categoryData[i]?.selected === true) {
        categories.push(categoryData[i]?.label);
      }
    }
    setShowLoader(true);
    axios
      .post(
        "generate_mcqs/",
        {
          categories,
          no_of_mcqs: parseInt(noOfMcqs),
          stories: possibleCombinationsData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        let items = [];
        for (let k = 0; k < res?.data?.result?.length; k++) {
          for (let i = 0; i < res?.data?.result[k]?.length; i++) {
            for (let j = 0; j < res?.data?.result[k][i]?.mcqs?.length; j++) {
              if (res?.data?.result[k][i]?.mcqs?.length > 0) {
                let allOptions = [
                  ...res?.data?.result[k][i]?.mcqs[j]?.other_options,
                ];
                allOptions.push(
                  `${res?.data?.result[k][i]?.mcqs[j]?.correct_option} *`
                );
                let mcqBody = {
                  story: res?.data?.result[k][i]?.mcqs[j]?.story,
                  question: res?.data?.result[k][i]?.mcqs[j]?.question,
                  category: res?.data?.result[k][i]?.mcqs[j]?.category ?? "",
                  corectOption:
                    res?.data?.result[k][i]?.mcqs[j]?.correct_option,
                  alternativeOptions: shuffleArray(allOptions),
                };
                items.push(mcqBody);
              }
            }
          }
        }
        setShowLoader(false);
        setMcqItems(items);
      })
      .catch((err) => {
        console.log(err, "err");
        setShowLoader(false);
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }
  function MakeNewStory() {
    let array = [...story];
    let baseStr1 = array[0];
    let arr = [];
    for (let i = 11; i < 14; i++) {
      let regex = new RegExp(`\\.([^\\.]*\\(${`(${i})`}\\)[^\\.]*)\\.`, "g");
      if (baseStr1.match(regex)) {
        let matchedText = baseStr1.match(regex)[0].replace(/\. (\w+)/, "$1");
        if (/^\.\s/.test(matchedText)) {
          matchedText = matchedText.replace(/^\.\s/, "");
        }
        arr.push(matchedText);
      }
    }
    const combinations = [];
    for (let i = 11; i < 25; i++) {
      const regex = new RegExp(`\\.([^\\.]*\\(${`(${i})`}\\)[^\\.]*)\\.`, "g");
      let hasText = regex.test(baseStr1);
      if (hasText) {
        baseStr1 = baseStr1.replace(regex, ".");
      }
    }
    for (let i = 0; i < 2 ** arr.length; i++) {
      let str = baseStr1;
      for (let j = 0; j < arr.length; j++) {
        if (i & (1 << j)) {
          str += ` ${arr[j]}`;
        }
      }
      combinations.push(str);
    }
    setStory(combinations);
    setSplitStory(false);
    onStoryInputSubmit();
  }
  function onSubmitStories(e) {
    e.preventDefault();
    // generate()
    generateMcqs();
  }
  function generate() {
    setMcqItems([]);
    let variableUsed = [];
    for (let i = 0; i < Object.keys(fields).length; i++) {
      variableUsed.push(Object.keys(fields)[i]);
    }
    let storyVar = [];
    for (let s = 0; s < story.length; s++) {
      let variableArray = [];
      for (let j = 0; j < variableUsed.length; j++) {
        if (story[s].includes(`(${variableUsed[j]})`)) {
          variableArray.push(variableUsed[j]);
        }
      }
      storyVar.push(variableArray);
    }
    let categories = [];
    for (let i = 0; i < categoryData.length; i++) {
      if (categoryData[i]?.selected === true) {
        categories.push(categoryData[i]?.label);
      }
    }
    if (noOfMcqs && categories.length > 0) {
      setShowLoader(true);
      axios
        .post(
          "fetch_question_by_cases/",
          {
            topic,
            variableUsed,
            noOfMcqs,
            categories,
            casesList,
            storyVar,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("access")}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          let data = res?.data;
          if (res?.data[0]?.all_questions) {
            let stories = [];
            for (let i = 0; i < Object.keys(story).length; i++) {
              let storyParameter = story[Object.keys(story)[i]];
              stories.push(
                getAllStories(
                  storyParameter,
                  data[i]?.all_sets,
                  data[i]?.variable_used
                )
              );
            }
            let items = [];
            for (let k = 0; k < Object.keys(story).length; k++) {
              for (let i = 0; i < stories[k].length; i++) {
                let mcqBody = {
                  story: stories[k][i],
                  question: data[k]?.all_questions[i].question,
                  category: data[k]?.all_questions[i].category,
                  corectOption: data[k]?.all_questions[i].correct_option,
                  alternativeOptions:
                    data[k]?.all_questions[i].alternative_options,
                };
                items.push(mcqBody);
              }
            }
            setShowLoader(false);
            setMcqItems(items);
          } else {
            alert(
              `Sorry! We didn't find any case that fits to codes used in your story.`
            );
            setShowLoader(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          if (err?.response?.status === 401) {
            console.log(err);
            window.localStorage.setItem("isLoggedIn", "false");
            navigate("/login");
          }
        });
    } else {
      alert("choose a category first");
    }
  }
  function removeElAtIndex(arr, index) {
    return arr.slice(0, index).concat(arr.slice(index + 1));
  }
  function onStoryInputSubmit() {
    setMcqItems([]);
    setFields([]);
    setVariableAlert("");
    const newFields = {};
    let string = "";
    story.map((itemstory, i) => {
      let item = (itemstory || "[]").trim().match(/\([a-z0-9_-]+\)/gi) || [];
      let nonExistingVariables = [];
      for (let i = 0; i < item.length; i++) {
        if (!codes.includes(parseInt(item[i].slice(1, item[i].length - 1)))) {
          nonExistingVariables.push(item[i].slice(1, item[i].length - 1));
        }
      }
      if (nonExistingVariables.length > 0) {
        string = nonExistingVariables.map((i) => i).join(", ");
      }
      item &&
        item
          .map((el) => el.slice(1, el.length - 1))
          .forEach((el) => {
            newFields[el] = fields[el] || [];
          });
      return null;
    });
    if (string !== "") {
      setVariableAlert(string);
    } else {
      setFields(newFields);
    }
  }
  function onStoryInputChange(index, newVal) {
    const newStory = story.slice();
    newStory[index] = newVal;
    setStory(newStory);
    setPossibleCombinations(0);
  }
  function onStoryRemove(index) {
    setStory(removeElAtIndex(story, index));
    setStoryInputRef(removeElAtIndex(storyInputRef, index));
    const arr = removeElAtIndex(possibleCombinationsData, index);
    setPossibleCombinationsData(arr);
    let minStoryTotal = Number.MAX_SAFE_INTEGER;
    for (const data of arr) {
      if (data.story_total < minStoryTotal) {
        minStoryTotal = data.story_total;
      }
    }
    if (minStoryTotal && arr.length > 0) {
      setPossibleCombinations(minStoryTotal);
    } else {
      setPossibleCombinations(0);
    }
  }
  function onStoryInputSaveRef(index, ref) {
    let arr = storyInputRef;
    arr[index] = ref;
    setStoryInputRef(arr);
  }
  function onStoryInputAdd() {
    setStory(story.concat(""));
    // storyInputRef[story.length - 1].focus();
  }
  const updateFieldChanged = (index) => {
    let newArr = [...categoryData];
    newArr[index].selected = !newArr[index].selected;
    setCategoryData(newArr);
  };
  function getAllStories(storyParameter, setData, variables) {
    const allStories = [];
    for (let i = 0; i < setData.length; i++) {
      let s = storyParameter;
      let ageIndex;
      let genderIndex;
      if (s?.includes("(1)") && s?.includes("(2)")) {
        ageIndex = variables?.indexOf("1");
        genderIndex = variables?.indexOf("2");
      }
      for (let j = 0; j < setData[i].length; j++) {
        if (
          typeof ageIndex !== "undefined" &&
          typeof genderIndex !== "undefined"
        ) {
          if (setData[i][ageIndex] > 19 && j === genderIndex) {
            s = s.replace(
              "(" + variables[j] + ")",
              setData[i][j] === "male"
                ? "man"
                : setData[i][j] === "female"
                ? "woman"
                : setData[i][j]
            );
          } else {
            s = s.replace(
              "(" + variables[j] + ")",
              setData[i][j] === "male"
                ? "boy"
                : setData[i][j] === "female"
                ? "girl"
                : setData[i][j]
            );
          }
        } else {
          s = s.replace("(" + variables[j] + ")", setData[i][j]);
        }
      }
      allStories.push(s);
    }
    return allStories;
  }
  function handleClick(event, link) {
    if (event.ctrlKey) {
      event.preventDefault();
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  }
  useEffect(() => {
    axios
      .get("fetch_user_info/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setProgress(100);
        setShowPage(true);
        let obj = {
          username: res?.data?.username,
          image: res?.data?.image,
        };
        dispatch({
          type: "USER_INFO",
          payload: obj,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }, [navigate]);
  
  useEffect(() => {
    const selectedTheme = topicData.find(item => item.value === topic);
    if (!selectedTheme){
      return
    }
    const fetchCategoriesInTheme = async () => {
      await axios
      .get("fetch_categories_by_theme/", {params: {topic: selectedTheme.id}}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.categories) {
          const categories = res.data.categories;
          setThemeCategories(categories);
          const updatedCategories = categoryData.map((item) => ({
            ...item,
            isInTheme: categories.includes(item.label),
          }));
          setCategoryData(updatedCategories)
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
    }
    fetchCategoriesInTheme()
  }, [topic])
  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {showPage && (
        <main className="App">
          <Outlet />
          <Sidebar />
          <Header
            name="Automatic Item Generator v.4 (AIG-4)"
            className="mb-5"
          />
          <Navigation navType="home" />
          {!showTopic ? (
            <div className="text-center">
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                style={{ color: "white" }}
              />
            </div>
          ) : (
            <div className="main-bd" style={{ marginTop: "3rem" }}>
              <SelectTopic
                topic={topic}
                setTopic={setTopic}
                topicData={topicData}
              />
              {/* </div> */}
              {topic !== "" && (
                <div className="my-5">
                  <Title level={3} className="mb-3">
                    {HEADING.questionCategories}:
                  </Title>
                  <div
                    className=""
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {categoryData?.map((elem, index) => {
                      return (
                        <div
                          key={index}
                          className="mb-2 col-xl-3 col-lg-4 col-md-4 col-12"
                          style={elem?.isInTheme ? { color: "aquamarine" ,whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                        >
                          <div style={{ display: "inline-block" }}>
                            <Form.Check
                              aria-label={`category ${index}`}
                              checked={elem?.selected}
                              onChange={() => updateFieldChanged(index)}
                            />
                          </div>
                          <div style={{ display: "inline-block" }}>
                            &nbsp;{elem?.label}&nbsp;&nbsp;
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {variables?.length > 0 &&
                categoryData?.filter((elem) => elem.selected === true).length >
                  0 && (
                  <div className="my-5">
                    <Title level={3} className="mb-3">
                      {HEADING.variables}:
                    </Title>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {variables?.length > 0 &&
                        variables?.map((elem, index) => {
                          return (
                            <>
                              <div
                                className="mb-2 col-xl-3 col-lg-4 col-md-4 col-12"
                                key={index}
                                style={{
                                  // flexBasis: "25%",
                                  whiteSpace: "nowrap",
                                  // padding: "5px 0",
                                }}
                              >
                                {elem?.name} ({elem?.code})&nbsp;
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
              {categoryData?.filter((elem) => elem.selected === true).length >
                0 && (
                <div className="my-5">
                  <Title level={3} className="mb-3">
                    {HEADING.story}:
                  </Title>
                  {variableAlert && (
                    <Space style={{ marginBottom: "0.5rem" }}>
                      <Alert
                        style={{}}
                        message={`variable(s) do(es) not exist against following code(s) ${variableAlert}`}
                        type="error"
                        showIcon
                        closable
                      />
                    </Space>
                  )}
                  {story.map((el, index) => (
                    <div key={index}>
                      <StoryInput
                        value={el}
                        index={index}
                        onSaveRef={onStoryInputSaveRef}
                        onRemove={onStoryRemove}
                        onInputChange={onStoryInputChange}
                      />
                    </div>
                  ))}
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center justify-content-center me-2 bank"
                      type="dashed"
                      onClick={onStoryInputAdd}
                      style={{ width: "30%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Story
                    </Button>
                    <Button
                      className="d-flex align-items-center justify-content-center process bank"
                      type="dashed"
                      onClick={getMoreStories}
                      style={{ width: "20%" }}
                    >
                      Process Vignette
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {<SelectCategory />} */}
          {showMcqLoader && topic ? (
            <center>
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                style={{ color: "white" }}
              />
            </center>
          ) : (
            <>
              {categoryData?.filter((elem) => elem.selected === true).length >
                0 && (
                <>
                  {possibleCombinations !== 0 && (
                    <div>
                      <Row
                        style={{
                          verticalAalign: "middle",
                          marginBottom: "35px",
                        }}
                      >
                        <Col>
                          <h4 style={{ marginTop: "5px" }}>
                            Enter No of MCQs To Generate: &nbsp;{" "}
                          </h4>
                        </Col>
                        <form onSubmit={(e) => onSubmitStories(e)}>
                          <Col className="d-flex">
                            <Input
                              style={{
                                height: "40px",
                                width: "100px",
                                borderRadius: "0.5rem",
                              }}
                              placeholder={
                                story.length + " - " + possibleCombinations
                              }
                              type="number"
                              min={story.length}
                              max={possibleCombinations}
                              name={noOfMcqs}
                              value={noOfMcqs !== 0 && noOfMcqs}
                              onChange={(e) => {
                                if (e.target.value <= possibleCombinations) {
                                  setNoOfMcqs(e.target.value);
                                }
                              }}
                            />
                            &nbsp;
                            <Button
                              size="large"
                              icon={<SafetyOutlined />}
                              disabled={showLoader || noOfMcqs < story.length}
                              onClick={(e) => onSubmitStories(e)}
                              className="d-flex align-items-center ms-1 generate"
                              id="addNew"
                            >
                              <>{HEADING.previewButton} (Questions)</>
                            </Button>
                            {showLoader && (
                              <Dna
                                visible={true}
                                height="50"
                                width="80"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                                style={{ color: "white" }}
                              />
                            )}
                          </Col>
                        </form>
                      </Row>
                    </div>
                  )}
                  {mcqItems.length > 0 && (
                    <MCQ MCQsItems={mcqItems} topic={topic} />
                  )}
                </>
              )}
            </>
          )}
        </main>
      )}
    </>
  );
}

export default NavigateWrapper;
